.__react_component_tooltip {
  @apply w-10/12 max-w-xs filter drop-shadow-md text-base p-0 bg-transparent leading-tight hidden !important;
}
.__react_component_tooltip.show {
  @apply opacity-100 inline-block !important;
}

.__react_component_tooltip::before,
.__react_component_tooltip::after {
  @apply hidden sm:block;
}

/* Using mas-width since we only want to override these values for xs and sm */
@media (max-width: 640px) {
  #tooltip.__react_component_tooltip {
    @apply left-0 right-0 !important;
  }
}

.tooltip-content > *{
 @apply mt-2 text-violet text-sm font-normal; 
}